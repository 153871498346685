import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { countries } from "countries-list";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import LogoImg from "../../assets/png/logoImg1.png";
import SideImg from "../../assets/png/signInImg1.png";
import { singInAPI } from "../../redux/auth/singIn";
import Style from "../../styles/auth.module.scss";
import {
  AutocompleteUI,
  OutlinedInputUI,
  TextFieldUI,
} from "../../theme/muiTheme";
import Snack from "../../ui/Snack";
import { singUpOrgAPI } from "../../redux/auth/singUpOrg";
import FileUploader from "../../ui/FileUploader";

const schema = yup
  .object({
    name: yup
      .string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .trim()
      .required("Name is required field"),
    email: yup.string().trim().required("Email is required field"),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .trim()
      .required("Password is required field"),
    confirmPassword: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .trim()
      .required("Confirm password is required field"),
    description: yup.string().trim().required("Description is required field"),
    websiteUrl: yup.string().trim().required("Website url is required field"),
    country: yup.string().trim().required("Country is required field"),
    mobile: yup
      .string()
      .matches(/^\d{10}$/, "Mobile number should have only 10 digits")
      .trim()
      .required("Mobile number is required field"),
    // documents: yup
    //   .array()
    //   .min(1, "At least one document is required")
    //   .required("Documents are required"),
  })
  .required();

export default function SignUporg() {
  const singInSelector = useSelector((state) => state?.singIn);
  let { message, error } = singInSelector;

  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [location, setLocation] = useState(null);
  const [passwordShow, setPasswordShow] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [confpasswordShow, setConfPasswordShow] = useState(false);

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    const updatedFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (allowedTypes.includes(file.type)) {
        const existingFileIndex = uploadedFiles.findIndex(
          (uploadedFile) => uploadedFile.name === file.name
        );
        if (existingFileIndex === -1) {
          updatedFiles.push(file);
        } else {
          toast.error(`File '${file.name}' already uploaded.`, {
            duration: 5000,
            position: "bottom-left",
          });
        }
      } else {
        toast.error(`Invalid file type: ${file.name.split(".").pop()}.`, {
          duration: 5000,
          position: "bottom-left",
        });
      }
    }

    setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
  };

  const handleSignUp = async (data) => {
    // if (uploadedFiles.length === 0) {
    //   toast.error("Document is required. Add at least one document.");
    //   return;
    // }
    // const formData = { ...data, documents: uploadedFiles };
    // const signIn = await dispatch(
    //   singUpOrgAPI({ ...formData })
    if(data.password !== data.confirmPassword){
      toast.error('Password does not match', {
        duration: 5000,
        position: "bottom-left",
      });
      return;
    }
    const signUp = await dispatch(
      // singUpOrgAPI({ type: "Organization", ...data })
      singUpOrgAPI({ type: "Organization", ...data })
    ).then((res) => {
      // console.log(res);
      if (!res?.payload.error) {
        window.location.href = "/signIn";
      }
    });
    // await dispatch(singUpOrgAPI(data)).then((res) => {
    //   console.log(res);
    //   window.location.href = "/signin";
    // });
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
      });
    }
  }, []);

  return (
    <>
      <Grid container className={Style.login}>
        <Grid
          item
          lg={4}
          md={4}
          sm={5}
          xs={12}
          className={Style?.signUp}
          marginBottom={{ xs: "50px", lg: "0px" }}
        >
          <Stack sx={{ p: 4, width: "100%" }}>
            <Box sx={{ width: 150 }}>
              <Link to="/">
                {" "}
                <img src={LogoImg} alt="Eduthum" width={"100%"} />
              </Link>
            </Box>
            <Typography variant="h4" sx={{ mt: "1rem", color: "#fff" }}>
              Welcome !
            </Typography>
            <Typography variant="h3" className={Style.signInText}>
              Sign up
            </Typography>
            <form onSubmit={handleSubmit(handleSignUp)}>
              <Stack spacing={2} sx={{ mt: 5 }}>
                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>Name</FormLabel>
                  <TextFieldUI
                    placeholder="Enter your name"
                    inputProps={{ style: { color: "#000" } }}
                    type="name"
                    {...register("name")}
                  />
                  <FormHelperText error>{errors?.name?.message}</FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>Email</FormLabel>
                  <TextFieldUI
                    placeholder="Enter your email"
                    inputProps={{ style: { color: "#000" } }}
                    type="email"
                    {...register("email")}
                  />
                  <FormHelperText error>
                    {errors?.email?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>Password</FormLabel>
                  <OutlinedInputUI
                    placeholder="Enter your password"
                    inputProps={{ style: { color: "#000" } }}
                    type={!passwordShow ? "password" : "text"}
                    endAdornment={
                      !passwordShow ? (
                        <IconButton onClick={() => setPasswordShow(true)}>
                          <VisibilityOffIcon color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setPasswordShow(false)}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      )
                    }
                    {...register("password")}
                  />
                  <FormHelperText error>
                    {errors?.password?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>
                    Confirm Password
                  </FormLabel>
                  <OutlinedInputUI
                    placeholder="Confirm your password"
                    inputProps={{ style: { color: "#000" } }}
                    type={!confpasswordShow ? "password" : "text"}
                    endAdornment={
                      !confpasswordShow ? (
                        <IconButton onClick={() => setConfPasswordShow(true)}>
                          <VisibilityOffIcon color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setConfPasswordShow(false)}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      )
                    }
                    {...register("confirmPassword")}
                  />
                  <FormHelperText error>
                    {errors?.confirmPassword?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>Description</FormLabel>
                  <TextFieldUI
                    placeholder="Enter the organization description"
                    multiline
                    rows={3}
                    inputProps={{ style: { color: "#000" } }}
                    {...register("description")}
                  />
                  <FormHelperText error>
                    {errors?.description?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>Website Url</FormLabel>
                  <TextFieldUI
                    placeholder="Enter the organisation's website URL"
                    inputProps={{ style: { color: "#000" } }}
                    {...register("websiteUrl")}
                  />
                  <FormHelperText error>
                    {errors?.websiteUrl?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>Country</FormLabel>
                  <AutocompleteUI
                    disablePortal
                    options={Object?.values(countries)?.map((ele) => ele?.name)}
                    renderInput={(params) => (
                      <TextFieldUI
                        placeholder="Select your country"
                        {...params}
                        {...register("country")}
                      />
                    )}
                  />
                  <FormHelperText error>
                    {errors?.country?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>Mobile</FormLabel>
                  <TextFieldUI
                    placeholder="Enter your mobile number"
                    inputProps={{ style: { color: "#000" } }}
                    {...register("mobile")}
                  />
                  <FormHelperText error>
                    {errors?.mobile?.message}
                  </FormHelperText>
                </FormControl>

                {/* Drag and Drop Feature */}
                {/* <FileUploader
                  handleFileDrop={handleFileDrop}
                  handleFileUpload={handleFileUpload}
                />
                {uploadedFiles.map((file, index) => (
                  <Typography key={index} style={{ color: "#35abaf" }}>
                    Uploaded File {index + 1}: {file.name}
                  </Typography>
                ))} */}

                <Button variant="contained" type="submit" size="large">
                  Register
                </Button>
              </Stack>
            </form>

            <Stack spacing={1} alignItems="center" sx={{ mt: 2 }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography>Don't have an Account ?</Typography>
                <Link to="/signUp">
                  <Typography sx={{ color: "#35abaf" }}>Sign Up</Typography>
                </Link>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography>Already have an Account ?</Typography>
                <Link to="/signIn">
                  <Typography sx={{ color: "#35abaf" }}>Sign In</Typography>
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={6}
          xs={12}
          className={Style?.bgImage}
          display={{ xs: "none", lg: "block" }}
        >
          <Box className={Style?.sideImg}>
            <img src={SideImg} alt="background image" width={"100%"} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
