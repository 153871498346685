import { yupResolver } from "@hookform/resolvers/yup";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/header/Header";
import { updateProfileAPI } from "../../redux/user/updateProfile";
import { uploadFiles, uploadMultipleFiles } from "../../service/upload";
import DeleteIcon from "@mui/icons-material/Delete";
import Style from "../../styles/editProfile.module.scss";
import Snack from "../../ui/Snack";
import { getCurrentUserAPI } from "../../redux/user/getCurrentUser";
import toast from "react-hot-toast";
import DisplayItem from "../../ui/DisplayItem";
import { getCurrentUserOrgAPI } from "../../redux/user/getCurrentUserOrg";
import { deleteCurrentUserOrgDocAPI } from "../../redux/user/deleteCurrentUserOrgDoc";

const schema = yup
  .object({
    name: yup
      .string()
      .trim()
      .required("Name is required")
      .min(3, "Name must be at least 3 characters.")
      .matches(/^[a-zA-Z\s]+$/, "Name must not contain special characters"),
    userName: yup.string().trim().required("User Name is required"),
    bio: yup.string().trim(),
    social: yup.object().shape({
      facebook: yup.string().url("Invalid Facebook URL"),
      twitter: yup.string().url("Invalid Twitter URL"),
      instagram: yup.string().url("Invalid Instagram URL"),
      youtube: yup.string().url("Invalid YouTube URL"),
      discord: yup.string().url("Invalid Discord URL"),
      linkedin: yup.string().url("Invalid LinkedIn URL"),
    }),
  })
  .required();

const EditProfile = () => {
  const [imageUrl, setImageUrl] = useState({ cover: "", profile: "" });
  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const getCurrentUserOrgSelector = useSelector(
    (state) => state?.getCurrentUserOrg
  );

  const { result: user } = getCurrentUserSelector;
  const { result: org } = getCurrentUserOrgSelector;

  console.log(">>", org);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const [profileImage, setProfileImage] = useState({
    cover: null,
    picture: null,
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isChangeProfilePic, setIsChangeProfilePic] = useState(false);
  const [isChangeProfileCover, setIsChangeProfileCover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleUploadPic = async ({ target }) => {
    const files = target.files?.[0];
    const data = await uploadFiles(files);
    setProfileImage({ ...profileImage, picture: data });
    setImageUrl((prev) => ({
      ...prev,
      profile: data.fileUrl,
    }));
    setIsChangeProfilePic(false);
  };

  const handleUploadCover = async ({ target }) => {
    const files = target.files?.[0];
    const data = await uploadFiles(files);
    setProfileImage({ ...profileImage, cover: data });
    setImageUrl((prev) => ({
      ...prev,
      cover: data.fileUrl,
    }));
    setIsChangeProfileCover(false);
  };

  const handleFiles = (files) => {
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    const updatedFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (allowedTypes.includes(file.type)) {
        const existingFileIndex = uploadedFiles.findIndex(
          (uploadedFile) => uploadedFile.name === file.name
        );
        if (existingFileIndex === -1) {
          updatedFiles.push(file);
        } else {
          toast.error(`File '${file.name}' already uploaded.`, {
            duration: 5000,
            position: "bottom-left",
          });
        }
      } else {
        toast.error(`Invalid file type: ${file.name.split(".").pop()}.`, {
          duration: 5000,
          position: "bottom-left",
        });
      }
    }

    setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
  };

  const handleFileDrop = async (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleEditProfile = async (data) => {
    if (user.type === "Organization" || user?.country !== "India") {
      const uploadedData = await uploadMultipleFiles(uploadedFiles);
      await dispatch(
        updateProfileAPI({
          ...data,
          cover: profileImage?.cover?.fileUrl,
          picture: profileImage?.picture?.fileUrl,
          files: uploadedData,
        })
      );
    } else {
      await dispatch(
        updateProfileAPI({
          ...data,
          cover: profileImage?.cover?.fileUrl,
          picture: profileImage?.picture?.fileUrl,
        })
      );
    }

    dispatch(getCurrentUserAPI());
    navigate(`/profile/${user?._id}`);
    reset();
  };

  const handleRemoveFile = (fileToRemove) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const handleDocDelete = async (id) => {
    await dispatch(deleteCurrentUserOrgDocAPI({ docId: id }));
    await dispatch(getCurrentUserOrgAPI());
  };

  useEffect(() => {
    const defaultValues = {
      name: user?.name,
      userName: user?.userName,
      bio: user?.bio,
      email: user?.email,
      social: {
        facebook: user?.social?.facebook,
        linkedin: user?.social?.linkedin,
        twitter: user?.social?.twitter,
        instagram: user?.social?.instagram,
        youtube: user?.social?.youtube,
        discord: user?.social?.discord,
      },
    };

    reset({ ...defaultValues });
  }, [user]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      await dispatch(getCurrentUserAPI());
      await dispatch(getCurrentUserOrgAPI());
    };

    fetchUserDetails();
  }, []);

  return (
    <>
      <Box className={Style?.main}>
        <Header />
        <Box className={Style?.container}>
          <Grid container justifyContent="center" sx={{ p: 2 }}>
            <Grid lg={5} md={7} sm={9} xs={12}>
              <Card>
                <form onSubmit={handleSubmit(handleEditProfile)}>
                  <Stack spacing={2}>
                    <Typography variant="h5" align="center">
                      Edit Profile
                    </Typography>

                    <Box className="dropCard">
                      {!isChangeProfilePic && (
                        <>
                          <Button
                            variant="outlined"
                            onClick={() => setIsChangeProfilePic(true)}
                          >
                            {imageUrl?.profile || user?.picture
                              ? "Change Profile Picture"
                              : "Upload Profile Picture"}
                          </Button>
                          {imageUrl?.profile || user?.picture ? (
                            <img
                              src={imageUrl?.profile || user?.picture}
                              alt="User's pic"
                              width={"100%"}
                              height={"200px"}
                              style={{ paddingTop: "8px" }}
                            />
                          ) : (
                            <Typography
                              variant="caption"
                              style={{ paddingTop: "8px" }}
                            >
                              Upload profile picture
                            </Typography>
                          )}
                        </>
                      )}
                      {isChangeProfilePic && (
                        <>
                          <Button
                            variant="outlined"
                            onClick={() => setIsChangeProfilePic(false)}
                            // sx={{ position: "absolute", top: 10, right: 10 }}
                          >
                            Back
                          </Button>
                          <input
                            accept=".jpg, .jpeg, .png"
                            style={{ display: "none" }}
                            id="raised-button-file"
                            type="file"
                            onChange={handleUploadPic}
                          />
                          <label htmlFor="raised-button-file">
                            <Stack
                              alignItems="center"
                              spacing={2}
                              sx={{ my: 2 }}
                            >
                              <Typography variant="h6">
                                Upload your profile picture!
                              </Typography>
                              <ArrowCircleUpIcon
                                sx={{
                                  width: 80,
                                  height: 80,
                                  bgcolor: "#999999",
                                  borderRadius: 10,
                                  p: 2,
                                }}
                              />
                              <Stack alignItems="center">
                                <Typography variant="subtitle2">
                                  JPG, JPEG, PNG
                                </Typography>
                              </Stack>
                              <Typography variant="subtitle2">
                                Max size: 100 MB
                              </Typography>
                            </Stack>
                          </label>
                        </>
                      )}
                    </Box>

                    <Box className="dropCard">
                      {!isChangeProfileCover && (
                        <>
                          <Button
                            variant="outlined"
                            onClick={() => setIsChangeProfileCover(true)}
                          >
                            {imageUrl?.cover || user?.cover
                              ? "Change Profile Cover"
                              : "Upload Profile Cover"}
                          </Button>
                          {imageUrl?.cover || user?.cover ? (
                            <img
                              src={imageUrl?.cover || user?.cover}
                              alt="User's profile cover"
                              width={"100%"}
                              height={"200px"}
                              style={{ paddingTop: "8px" }}
                            />
                          ) : (
                            <Typography
                              variant="caption"
                              style={{ paddingTop: "8px" }}
                            >
                              No profile cover uploaded yet.
                            </Typography>
                          )}
                        </>
                      )}
                      {isChangeProfileCover && (
                        <>
                          <Button
                            variant="outlined"
                            onClick={() => setIsChangeProfileCover(false)}
                          >
                            Back
                          </Button>
                          <input
                            accept=".jpg, .jpeg, .png"
                            style={{ display: "none" }}
                            id="raised-button-file-cover"
                            type="file"
                            onChange={handleUploadCover}
                          />
                          <label htmlFor="raised-button-file-cover">
                            <Stack
                              alignItems="center"
                              spacing={2}
                              sx={{ my: 2 }}
                            >
                              <Typography variant="h6">
                                Upload your profile cover!
                              </Typography>
                              <ArrowCircleUpIcon
                                sx={{
                                  width: 80,
                                  height: 80,
                                  bgcolor: "#999999",
                                  borderRadius: 10,
                                  p: 2,
                                }}
                              />
                              <Stack alignItems="center">
                                <Typography variant="subtitle2">
                                  JPG, JPEG, PNG
                                </Typography>
                              </Stack>
                              <Typography variant="subtitle2">
                                Max size: 100 MB
                              </Typography>
                            </Stack>
                          </label>
                        </>
                      )}
                    </Box>

                    {(user?.type === "Organization" ||
                      user?.country !== "India") && (
                      <Box
                        className="dropCard"
                        onDrop={handleFileDrop}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <Button
                          variant="outlined"
                          // onClick={toggleUploadedDocuments}
                          onClick={handlePopoverOpen}
                        >
                          Show Uploaded Documents
                        </Button>

                        <input
                          accept="application/pdf,image/jpeg,image/jpg,image/png"
                          style={{ display: "none" }}
                          id="upload-multiple-files"
                          type="file"
                          onChange={handleFileUpload}
                        />
                        <label htmlFor="upload-multiple-files">
                          <Stack
                            alignItems="center"
                            spacing={2}
                            sx={{ my: 2 }}
                            style={{ cursor: "pointer" }}
                          >
                            <Typography variant="h6">
                              Upload Your Documents!
                            </Typography>
                            <ArrowCircleUpIcon
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "#999999",
                                borderRadius: 10,
                                p: 2,
                              }}
                            />
                            <Stack alignItems="center">
                              <Typography variant="subtitle2">
                                PDF, JPG, JPEG, PNG
                              </Typography>
                            </Stack>
                            <Typography variant="subtitle2">
                              Max size: 100 MB
                            </Typography>
                          </Stack>
                        </label>
                        {uploadedFiles.map((file, index) => (
                          <DisplayItem
                            key={index}
                            file={file}
                            onRemove={handleRemoveFile}
                          />
                        ))}

                        {/* Popover */}
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          PaperProps={{
                            sx: {
                              width: 300,
                              padding: "10px",
                              backgroundColor: "#484848",
                            },
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            {org?.documents && org?.documents.length !== 0 ? (
                              org?.documents?.map((doc, idx) => (
                                <DisplayItem
                                  key={idx}
                                  file={doc}
                                  onRemove={handleDocDelete}
                                />
                              ))
                            ) : (
                              <Typography variant="caption">
                                No documents uploaded yet.
                              </Typography>
                            )}
                          </div>
                        </Popover>
                      </Box>
                    )}

                    <TextField
                      label="Display Name *"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      {...register("name")}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#232323", // Set background to transparent
                          margin: "0px",
                          padding: "3px 14px"
                        },
                      }}
                    />

                    <TextField
                      label="User Name *"
                      error={!!errors.userName}
                      helperText={errors.userName?.message}
                      {...register("userName")}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#232323", // Set background to transparent
                          margin: "0px",
                          padding: "3px 14px"
                        },
                      }}
                    />
                    <TextField
                      label="Email Address"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      disabled={true}
                      {...register("email")}
                      className={Style.emailField}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#232323", // Set background to transparent
                          margin: "0px",
                          padding: "3px 14px"
                        },
                      }}
                    />
                    <TextField multiline label="Bio" {...register("bio")} />
                    <TextField
                      label="Facebook Link"
                      error={!!errors.social?.facebook}
                      helperText={errors.social?.facebook?.message}
                      {...register("social.facebook")}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#232323", // Set background to transparent
                          margin: "0px",
                          padding: "3px 14px"
                        },
                      }}
                    />
                    <TextField
                      label="Linkedin Link"
                      error={!!errors.social?.linkedin}
                      helperText={errors.social?.linkedin?.message}
                      {...register("social.linkedin")}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#232323", // Set background to transparent
                          margin: "0px",
                          padding: "3px 14px"
                        },
                      }}
                    />
                    <TextField
                      label="Twitter Link"
                      error={!!errors.social?.twitter}
                      helperText={errors.social?.twitter?.message}
                      {...register("social.twitter")}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#232323", // Set background to transparent
                          margin: "0px",
                          padding: "3px 14px"
                        },
                      }}
                    />
                    <TextField
                      label="Instagram Link"
                      error={!!errors.social?.instagram}
                      helperText={errors.social?.instagram?.message}
                      {...register("social.instagram")}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#232323", // Set background to transparent
                          margin: "0px",
                          padding: "3px 14px"
                        },
                      }}
                    />
                    <TextField
                      label="Youtube Link"
                      error={!!errors.social?.youtube}
                      helperText={errors.social?.youtube?.message}
                      {...register("social.youtube")}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#232323", // Set background to transparent
                          margin: "0px",
                          padding: "3px 14px"
                        },
                      }}
                    />
                    <TextField
                      label="Discord Link"
                      error={!!errors.social?.discord}
                      helperText={errors.social?.discord?.message}
                      {...register("social.discord")}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#232323", // Set background to transparent
                          margin: "0px",
                          padding: "3px 14px"
                        },
                      }}
                    />

                    <Button variant="contained" type="submit">
                      Edit Profile
                    </Button>
                  </Stack>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default EditProfile;
